import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';

const HospitalManagement = () => {
    const [hospitals, setHospitals] = useState([]);
    const [newHospital, setNewHospital] = useState({ name: '', subdomain: '' });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchHospitals = async () => {
            try {
                const hospitalsCollection = collection(db, 'hospitals');
                const hospitalSnapshot = await getDocs(hospitalsCollection);
                const hospitalList = hospitalSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setHospitals(hospitalList);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching hospitals:", err);
                setError("Failed to load hospitals. Please try again later.");
                setLoading(false);
            }
        };
        fetchHospitals();
    }, []);

    const addHospital = async (e) => {
        e.preventDefault();
        try {
            const docRef = await addDoc(collection(db, 'hospitals'), newHospital);
            setHospitals([...hospitals, { id: docRef.id, ...newHospital }]);
            setNewHospital({ name: '', subdomain: '' });
        } catch (err) {
            console.error("Error adding hospital:", err);
            setError("Failed to add hospital. Please try again.");
        }
    };

    const updateHospital = async (id, updatedData) => {
        try {
            const hospitalRef = doc(db, 'hospitals', id);
            await updateDoc(hospitalRef, updatedData);
            setHospitals(hospitals.map(hosp =>
                hosp.id === id ? { ...hosp, ...updatedData } : hosp
            ));
        } catch (err) {
            console.error("Error updating hospital:", err);
            setError("Failed to update hospital. Please try again.");
        }
    };

    const deleteHospital = async (id) => {
        try {
            await deleteDoc(doc(db, 'hospitals', id));
            setHospitals(hospitals.filter(hosp => hosp.id !== id));
        } catch (err) {
            console.error("Error deleting hospital:", err);
            setError("Failed to delete hospital. Please try again.");
        }
    };

    if (loading) return <div>Loading hospitals...</div>;
    if (error) return <div className="text-red-500">{error}</div>;

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Hospital Management</h2>
            <form onSubmit={addHospital} className="mb-4">
                <input
                    type="text"
                    placeholder="Hospital Name"
                    value={newHospital.name}
                    onChange={(e) => setNewHospital({ ...newHospital, name: e.target.value })}
                    className="mr-2 p-2 border rounded"
                />
                <input
                    type="text"
                    placeholder="Subdomain"
                    value={newHospital.subdomain}
                    onChange={(e) => setNewHospital({ ...newHospital, subdomain: e.target.value })}
                    className="mr-2 p-2 border rounded"
                />
                <button type="submit" className="bg-blue-500 text-white p-2 rounded">Add Hospital</button>
            </form>
            {hospitals.length === 0 ? (
                <p>No hospitals found.</p>
            ) : (
                <table className="w-full">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="p-2">Name</th>
                            <th className="p-2">Subdomain</th>
                            <th className="p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {hospitals.map(hospital => (
                            <tr key={hospital.id} className="border-b">
                                <td className="p-2">{hospital.name}</td>
                                <td className="p-2">{hospital.subdomain}</td>
                                <td className="p-2">
                                    <button onClick={() => updateHospital(hospital.id, { name: prompt("Enter new name", hospital.name) })} className="bg-yellow-500 text-white p-1 rounded mr-2">Edit</button>
                                    <button onClick={() => deleteHospital(hospital.id)} className="bg-red-500 text-white p-1 rounded">Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default HospitalManagement;