import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

const DepartmentSelection = ({ onSelect, hospital }) => {
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSubDepartment, setSelectedSubDepartment] = useState(null);
  const [showSubDepartments, setShowSubDepartments] = useState(false);

  useEffect(() => {
    const fetchDepartments = async () => {
      if (hospital && hospital.id) {
        const departmentsRef = collection(db, "departments");
        const q = query(departmentsRef, where("hospitalId", "==", hospital.id));
        const departmentsSnapshot = await getDocs(q);
        const departmentsList = departmentsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDepartments(departmentsList);
      }
    };

    fetchDepartments();
  }, [hospital]);

  const handleDepartmentSelect = (dept) => {
    setSelectedDepartment(dept);
    if (dept.hasSubDepartments) {
      setShowSubDepartments(true);
    } else {
      onSelect(dept, null);
    }
  };

  const handleSubDepartmentSelect = (subDept) => {
    setSelectedSubDepartment(subDept);
    onSelect(selectedDepartment, subDept);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-secondary p-4">
      <div className="w-full max-w-4xl bg-secondary rounded-lg shadow-md p-8 border border-primary">
        <h1 className="text-2xl font-bold text-center mb-6 text-primary">
          Integrated Patient Queue Management System
        </h1>
        <p className="text-xl text-center text-primary mb-6">
          {showSubDepartments
            ? "Which sub-department are you going to?"
            : "Which department are you going to?"}
        </p>

        <div className="grid grid-cols-2 gap-4">
          {!showSubDepartments
            ? departments.map((dept) => (
                <button
                  key={dept.id}
                  className={`w-full py-3 rounded-md text-center transition-colors duration-300 
                                    ${
                                      dept.id === selectedDepartment?.id
                                        ? "bg-accent text-secondary"
                                        : "bg-button text-primary hover:bg-accent"
                                    }`}
                  onClick={() => handleDepartmentSelect(dept)}
                >
                  {dept.name}
                </button>
              ))
            : selectedDepartment.subCategories.map((subDept, index) => (
                <button
                  key={index}
                  className={`w-full py-3 rounded-md text-center transition-colors duration-300 
                                    ${
                                      subDept === selectedSubDepartment
                                        ? "bg-accent text-secondary"
                                        : "bg-button text-primary hover:bg-accent"
                                    }`}
                  onClick={() => handleSubDepartmentSelect(subDept)}
                >
                  {subDept}
                </button>
              ))}
        </div>

        {showSubDepartments && (
          <button
            className="mt-4 px-4 py-2 bg-button text-primary rounded hover:bg-accent"
            onClick={() => setShowSubDepartments(false)}
          >
            Back to Departments
          </button>
        )}
      </div>
    </div>
  );
};

export default DepartmentSelection;
