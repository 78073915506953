// src/components/ConfirmationScreen.js
import React from 'react';

const ConfirmationScreen = ({ result, onConfirm, onRescan, isReturning }) => {
    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            {/* Top Logo */}
            <div className="bg-white p-4 shadow-md">
                <img
                    src="/logo.png"
                    alt="Rahima Moosa Mother and Child Hospital Logo"
                    className="w-32 h-16 object-contain mx-auto"
                />
            </div>

            {/* Main Content */}
            <div className="flex-grow flex items-center justify-center p-4">
                <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8">
                    <h2 className="text-2xl font-bold mb-4">
                        {isReturning ? "Welcome Back!" : "Confirm Details"}
                    </h2>
                    <div className="mb-4">
                        <p><strong>Name:</strong> {result.name}</p>
                        <p><strong>{result.documentType} Number:</strong> {result.documentId}</p>
                        <p><strong>Date of Birth:</strong> {result.birthDate}</p>
                        <p><strong>Expiry Date:</strong> {result.expiryDate}</p>
                        {isReturning && result.cellNumber && <p><strong>Cell Number:</strong> {result.cellNumber}</p>}
                    </div>
                    <div className="flex justify-between">
                        <button
                            onClick={onRescan}
                            className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 transition-colors duration-300"
                        >
                            Rescan
                        </button>
                        <button
                            onClick={() => onConfirm(result)}
                            className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 transition-colors duration-300"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>

            {/* Bottom Logo */}
            <div className="bg-white p-4 shadow-md">
                <img
                    src="/logo.png"
                    alt="Rahima Moosa Mother and Child Hospital Logo"
                    className="w-32 h-16 object-contain mx-auto"
                />
            </div>
        </div>
    );
};

export default ConfirmationScreen;