// src/components/admin/VisitorReports.js
import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';

const VisitorReports = () => {
  const [visitorData, setVisitorData] = useState([]);
  const [dateRange, setDateRange] = useState({ start: '', end: '' });

  useEffect(() => {
    fetchVisitorData();
  }, [dateRange]);

  const fetchVisitorData = async () => {
    const visitorsCollection = collection(db, 'visitors');
    let q = query(visitorsCollection, orderBy('createdAt', 'desc'));

    if (dateRange.start && dateRange.end) {
      const startDate = new Date(dateRange.start);
      const endDate = new Date(dateRange.end);
      endDate.setHours(23, 59, 59, 999);
      q = query(q, where('createdAt', '>=', startDate), where('createdAt', '<=', endDate));
    }

    const visitorSnapshot = await getDocs(q);
    const visitorList = visitorSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setVisitorData(visitorList);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Visitor Reports</h2>
      
      <div className="mb-4">
        <input
          type="date"
          value={dateRange.start}
          onChange={(e) => setDateRange({ ...dateRange, start: e.target.value })}
          className="mr-2 px-3 py-2 border rounded"
        />
        <input
          type="date"
          value={dateRange.end}
          onChange={(e) => setDateRange({ ...dateRange, end: e.target.value })}
          className="mr-2 px-3 py-2 border rounded"
        />
        <button onClick={fetchVisitorData} className="px-4 py-2 bg-blue-500 text-white rounded">
          Filter
        </button>
      </div>

      <table className="w-full">
        <thead>
          <tr>
            <th className="text-left">Name</th>
            <th className="text-left">Department</th>
            <th className="text-left">Visit Date</th>
          </tr>
        </thead>
        <tbody>
          {visitorData.map((visitor) => (
            <tr key={visitor.id}>
              <td>{visitor.name}</td>
              <td>{visitor.department}</td>
              <td>{visitor.createdAt.toDate().toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VisitorReports;