import React, { useEffect, useState } from "react";
import gautengLogo from "../assets/gauteng-logo.png";
import witsLogo from "../assets/wits-logo.png";
import DemiAdModal from "./DemiAdModal";

const Layout = ({ children, currentHospital }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsModalOpen(true);
    }, 5000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="flex flex-col h-screen bg-white">
      <header className="bg-white p-4 border-b border-gray-200">
        <div className="container mx-auto flex justify-between items-center">
          <div className="w-24">{/* Placeholder to balance the header */}</div>
          <div className="text-center">
            <h1 className="text-2xl font-bold text-black">
              Integrated Patient Queue Management System
            </h1>
            {currentHospital && (
              <h2 className="text-xl text-black">{currentHospital.name}</h2>
            )}
          </div>
          <img src={gautengLogo} alt="Gauteng Province" className="h-20" />
        </div>
      </header>

      <main className="flex-grow overflow-auto">{children}</main>

      <footer className="bg-gray-800 bg-opacity-50 text-white p-4">
        <div className="container mx-auto flex justify-center items-center">
          <img src={witsLogo} alt="Wits Intellitech" className="h-20 w-44" />
        </div>
      </footer>
      <DemiAdModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default Layout;
