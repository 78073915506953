// src/components/admin/QueueWaitingTimes.js
import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';

const QueueWaitingTimes = () => {
    const [queueData, setQueueData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [dateRange, setDateRange] = useState('today'); // 'today', 'week', 'month'

    useEffect(() => {
        fetchQueueData();
    }, [dateRange]);

    const fetchQueueData = async () => {
        setLoading(true);
        setError(null);
        try {
            const ticketsCollection = collection(db, 'tickets');
            const startDate = getStartDate();
            const q = query(
                ticketsCollection,
                where('createdAt', '>=', startDate),
                orderBy('createdAt', 'desc')
            );
            const ticketSnapshot = await getDocs(q);
            const ticketData = ticketSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            const groupedData = processTicketData(ticketData);
            setQueueData(Object.entries(groupedData));
        } catch (err) {
            console.error("Error fetching queue data:", err);
            setError("Failed to load queue data. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const getStartDate = () => {
        const now = new Date();
        switch (dateRange) {
            case 'week':
                return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
            case 'month':
                return new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
            default: // 'today'
                return new Date(now.getFullYear(), now.getMonth(), now.getDate());
        }
    };

    const processTicketData = (ticketData) => {
        return ticketData.reduce((acc, ticket) => {
            if (!acc[ticket.department]) {
                acc[ticket.department] = {
                    totalWaitTime: 0,
                    ticketCount: 0,
                    servedCount: 0,
                    longestWaitTime: 0,
                };
            }

            const waitTime = ticket.servedAt ? (ticket.servedAt - ticket.createdAt) / 60000 : 0; // in minutes
            acc[ticket.department].totalWaitTime += waitTime;
            acc[ticket.department].ticketCount++;
            if (ticket.servedAt) {
                acc[ticket.department].servedCount++;
            }
            acc[ticket.department].longestWaitTime = Math.max(acc[ticket.department].longestWaitTime, waitTime);

            return acc;
        }, {});
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className="text-red-500">{error}</div>;
    }

    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Queue Waiting Times</h2>
            <div className="mb-4">
                <label className="mr-2">Date Range:</label>
                <select
                    value={dateRange}
                    onChange={(e) => setDateRange(e.target.value)}
                    className="border rounded p-1"
                >
                    <option value="today">Today</option>
                    <option value="week">Last 7 Days</option>
                    <option value="month">Last 30 Days</option>
                </select>
            </div>
            <table className="w-full border-collapse border border-gray-300">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="border border-gray-300 p-2">Department</th>
                        <th className="border border-gray-300 p-2">Avg Wait Time (min)</th>
                        <th className="border border-gray-300 p-2">Longest Wait Time (min)</th>
                        <th className="border border-gray-300 p-2">Total Tickets</th>
                        <th className="border border-gray-300 p-2">Served Tickets</th>
                        <th className="border border-gray-300 p-2">Service Rate</th>
                    </tr>
                </thead>
                <tbody>
                    {queueData.map(([dept, data]) => (
                        <tr key={dept} className="hover:bg-gray-50">
                            <td className="border border-gray-300 p-2">{dept}</td>
                            <td className="border border-gray-300 p-2">{(data.totalWaitTime / data.servedCount).toFixed(2)}</td>
                            <td className="border border-gray-300 p-2">{data.longestWaitTime.toFixed(2)}</td>
                            <td className="border border-gray-300 p-2">{data.ticketCount}</td>
                            <td className="border border-gray-300 p-2">{data.servedCount}</td>
                            <td className="border border-gray-300 p-2">
                                {((data.servedCount / data.ticketCount) * 100).toFixed(2)}%
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default QueueWaitingTimes;