// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBNWI22pepaE7LNs7RzaI4mfLcrT-yxQO8",
    authDomain: "hospital-queue-managemen-b0e10.firebaseapp.com",
    projectId: "hospital-queue-managemen-b0e10",
    storageBucket: "hospital-queue-managemen-b0e10.appspot.com",
    messagingSenderId: "166413492977",
    appId: "1:166413492977:web:a44b5213de1880ee40ff50",
    measurementId: "G-QMP7YN018B"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { db, auth, analytics };