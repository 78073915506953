import React from "react";

const DemiAdModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
      <div className="bg-white p-4 rounded shadow-lg w-[40%] flex flex-col justify-center items-center">
        <div className="my-4 ">
          <div className="font-bold text-lg">App Activation required</div>
          <div>
            {" "}
            Then under in smaller To unlock functionality proceed with license
            activation.
          </div>
          <div>
            Contact{" "}
            <span className="text-[blue]">
              <a href="mailto:cat@cryptotracing.co.za">
                {" "}
                cat@cryptotracing.co.za
              </a>
            </span>{" "}
            to activate this licence.
          </div>
        </div>
        <button
          className="bg-red-500 text-white px-4 py-2 rounded "
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default DemiAdModal;
