import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

const IDPassportScan = ({ onCapture }) => {
    const videoRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const startCamera = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
                setStream(stream);
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            } catch (err) {
                console.error("Error accessing the camera:", err);
                setError("Failed to access camera. Please ensure you've granted camera permissions.");
            }
        };

        startCamera();

        return () => {
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
        };
    }, []);

    const handleCapture = async () => {
        setIsProcessing(true);
        setError(null);
        const canvas = document.createElement('canvas');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        canvas.getContext('2d').drawImage(videoRef.current, 0, 0);

        canvas.toBlob(async (blob) => {
            const formData = new FormData();
            formData.append('providers', 'amazon');
            formData.append('file', blob, 'image.jpg');

            try {
                console.log("Sending API request...");
                const response = await axios.post('https://api.edenai.run/v2/ocr/identity_parser', formData, {
                    headers: {
                        "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiOWFkOWE2NzAtZWZkNy00ZWVmLTgxYmMtMTNkODliYmM2OGQ3IiwidHlwZSI6ImFwaV90b2tlbiJ9.c58gMCyG_lQoLnQPyubeSCZVZlxWaSVdnUJcbZ5YuUY",
                        "Content-Type": "multipart/form-data"
                    }
                });

                console.log("API response:", response.data);

                if (response.data && response.data.amazon && response.data.amazon.extracted_data && response.data.amazon.extracted_data.length > 0) {
                    const extractedData = response.data.amazon.extracted_data[0];
                    console.log("Extracted data:", extractedData);
                    onCapture(extractedData);
                } else {
                    console.error("Unexpected API response structure:", response.data);
                    setError("Failed to extract data from the image. Please try again.");
                }
            } catch (error) {
                console.error("Error processing ID/Passport:", error);
                setError("An error occurred while processing the image. Please try again.");
            } finally {
                setIsProcessing(false);
            }
        }, 'image/jpeg');
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
            <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8">
                <h2 className="text-2xl font-bold mb-4">Scan ID/Passport</h2>
                <div className="mb-4">
                    <video ref={videoRef} autoPlay className="w-full h-64 bg-black" />
                </div>
                <button
                    onClick={handleCapture}
                    className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors duration-300"
                    disabled={isProcessing}
                >
                    {isProcessing ? 'Processing...' : 'Capture'}
                </button>
                {error && <p className="text-red-500 mt-2">{error}</p>}
            </div>
        </div>
    );
};

export default IDPassportScan;