import React, { useState } from 'react';

const UndocumentedForm = ({ onSubmit }) => {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [cellNumber, setCellNumber] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ name, surname, cellNumber });
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-secondary p-4">
            <div className="w-full max-w-md bg-secondary rounded-lg shadow-md p-8 border border-primary">
                <h2 className="text-2xl font-bold mb-4 text-primary text-center">Undocumented Patient Information</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="name" className="block text-primary text-sm font-bold mb-2">Name</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="shadow appearance-none border border-primary rounded w-full py-2 px-3 text-primary leading-tight focus:outline-none focus:shadow-outline bg-secondary"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="surname" className="block text-primary text-sm font-bold mb-2">Surname</label>
                        <input
                            type="text"
                            id="surname"
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                            className="shadow appearance-none border border-primary rounded w-full py-2 px-3 text-primary leading-tight focus:outline-none focus:shadow-outline bg-secondary"
                            required
                        />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="cellNumber" className="block text-primary text-sm font-bold mb-2">Cell Number</label>
                        <input
                            type="tel"
                            id="cellNumber"
                            value={cellNumber}
                            onChange={(e) => setCellNumber(e.target.value)}
                            className="shadow appearance-none border border-primary rounded w-full py-2 px-3 text-primary leading-tight focus:outline-none focus:shadow-outline bg-secondary"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-button hover:bg-accent text-primary font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default UndocumentedForm;