// src/components/CellNumberConfirmation.js
import React, { useState } from 'react';

const CellNumberConfirmation = ({ userType, isReturning, cellNumber, onSubmit }) => {
    const [number, setNumber] = useState(cellNumber || '');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(number);
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
            <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8">
                <h2 className="text-2xl font-bold mb-4">
                    {isReturning ? "Confirm Cell Number" : "Enter Cell Number"}
                </h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="tel"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        className="w-full px-3 py-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter cell number"
                        required
                    />
                    <button
                        type="submit"
                        className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors duration-300"
                    >
                        {isReturning ? "Confirm" : "Submit"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CellNumberConfirmation;