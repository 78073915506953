import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { collection, query, where, onSnapshot, orderBy, limit, getDocs } from 'firebase/firestore';

const QueueWaitingScreen = () => {
    const { department, subDepartment } = useParams();
    const [queueData, setQueueData] = useState([]);
    const [counterData, setCounterData] = useState([]);
    const [missedQueue, setMissedQueue] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [departmentId, setDepartmentId] = useState(null);

    useEffect(() => {
        const fetchDepartmentId = async () => {
            const deptsRef = collection(db, 'departments');
            const q = query(deptsRef, where('name', '==', department));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                setDepartmentId(querySnapshot.docs[0].id);
            }
        };
        fetchDepartmentId();
    }, [department]);

    useEffect(() => {
        if (!departmentId) return;

        console.log("Department:", department);
        console.log("Department ID:", departmentId);
        console.log("Sub-department:", subDepartment);

        let unsubscribeQueue;
        let unsubscribeCounter;
        let unsubscribeMissed;

        const fetchData = async () => {
            try {
                // Fetch queue data
                const queueRef = collection(db, 'queue');
                let queueQuery = query(
                    queueRef,
                    where('department', '==', departmentId),
                    where('status', '==', 'waiting'),
                    orderBy('timestamp', 'asc'),
                    limit(50)
                );
                if (subDepartment) {
                    queueQuery = query(queueQuery, where('subDepartment', '==', subDepartment));
                }

                unsubscribeQueue = onSnapshot(queueQuery, (snapshot) => {
                    const updatedQueue = snapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));
                    setQueueData(updatedQueue);
                });

                // Fetch counter data
                const counterRef = collection(db, 'ticketDesks');
                let counterQuery = query(
                    counterRef,
                    where('department', '==', departmentId),
                    limit(4)
                );
                if (subDepartment) {
                    counterQuery = query(counterQuery, where('subDepartment', '==', subDepartment));
                }

                unsubscribeCounter = onSnapshot(counterQuery, (snapshot) => {
                    const updatedCounter = snapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));
                    setCounterData(updatedCounter);
                });

                // Fetch missed queue
                const missedRef = collection(db, 'queue');
                let missedQuery = query(
                    missedRef,
                    where('department', '==', departmentId),
                    where('status', '==', 'missed'),
                    orderBy('timestamp', 'desc'),
                    limit(10)
                );
                if (subDepartment) {
                    missedQuery = query(missedQuery, where('subDepartment', '==', subDepartment));
                }

                unsubscribeMissed = onSnapshot(missedQuery, (snapshot) => {
                    const updatedMissed = snapshot.docs.map(doc => doc.data().queueNumber);
                    setMissedQueue(updatedMissed);
                });

                setLoading(false);
            } catch (err) {
                console.error("Error setting up listeners: ", err);
                setError("Failed to load data. Please try again later.");
                setLoading(false);
            }
        };

        fetchData();

        return () => {
            if (unsubscribeQueue) unsubscribeQueue();
            if (unsubscribeCounter) unsubscribeCounter();
            if (unsubscribeMissed) unsubscribeMissed();
        };
    }, [department, subDepartment, departmentId]);

    if (loading) {
        return <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
        </div>;
    }

    if (error) {
        return <div className="text-red-500 text-center p-4">{error}</div>;
    }

    return (
        <div className="flex flex-col h-full bg-white text-black">
            <div className="p-4 border-b border-gray-200">
                <h1 className="text-3xl font-bold text-center mb-4">
                    {department} {subDepartment && `- ${subDepartment}`} Queue
                </h1>
                <div className="grid grid-cols-4 gap-4">
                    {counterData.map((counter, index) => (
                        <div key={index} className="border border-gray-300 p-4 text-center">
                            <div className="text-blue-600 text-xl font-bold">COUNTER</div>
                            <div className="text-red-500 text-2xl font-bold">{counter.name}</div>
                            <div className="text-red-500 text-2xl font-bold">{counter.deskNumber}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex-grow overflow-auto p-4">
                <div className="grid grid-cols-3 gap-4">
                    {queueData.map((patient, index) => (
                        <div key={patient.id} className="border border-gray-300 p-4 text-center">
                            <div className="text-blue-600 text-lg font-bold">NEXT {index + 1}</div>
                            <div className="text-red-500 text-xl font-bold">{patient.patientName}</div>
                            <div className="text-red-500 text-xl font-bold">{patient.queueNumber}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="bg-blue-600 text-white p-4">
                <div className="text-2xl font-bold mb-2">MISSED QUEUE : {missedQueue.join(', ')}</div>
                <div className="overflow-hidden whitespace-nowrap">
                    <div className="inline-block animate-marquee">
                        Disclaimer: Queue number may not be called in sequence. Please pay attention to the screen at all times.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QueueWaitingScreen;