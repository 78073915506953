import React from 'react';

const RoleSelection = ({ onSelect, currentHospital }) => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-secondary p-4">
            <div className="w-full max-w-md bg-secondary rounded-lg shadow-md p-8 border border-primary">
                <h2 className="text-2xl font-bold mb-4 text-primary text-center">Select Your Role</h2>
                <div className="space-y-4">
                    <button
                        onClick={() => onSelect('Patient')}
                        className="w-full bg-button text-primary py-3 rounded-md hover:bg-accent transition-colors duration-300"
                    >
                        Patient
                    </button>
                    <button
                        onClick={() => onSelect('Visitor')}
                        className="w-full bg-button text-primary py-3 rounded-md hover:bg-accent transition-colors duration-300"
                    >
                        Visitor
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RoleSelection;