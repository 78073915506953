import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, query, where, onSnapshot, orderBy, updateDoc, doc, serverTimestamp, getDocs, limit } from 'firebase/firestore';
import gautengLogo from '../assets/gauteng-logo.png';
import { FaClock, FaHistory } from 'react-icons/fa';

const DeskServingScreen = ({ currentUser, hospital }) => {
    const { department, subDepartment, deskNumber } = useParams();
    const navigate = useNavigate();
    const [currentPatient, setCurrentPatient] = useState(null);
    const [waitingPatients, setWaitingPatients] = useState([]);
    const [totalServed, setTotalServed] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedSubDepartment, setSelectedSubDepartment] = useState('');
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [patientHistory, setPatientHistory] = useState([]);

    useEffect(() => {
        const fetchQueueData = async () => {
            if (!department) {
                console.error("Missing department parameter");
                setError("Missing department parameter");
                setLoading(false);
                return;
            }

            try {
                const queueRef = collection(db, 'queue');
                let q = query(
                    queueRef,
                    where('department', '==', department),
                    where('status', 'in', ['waiting', 'serving']),
                    orderBy('timestamp', 'asc')
                );

                if (subDepartment && subDepartment !== 'undefined') {
                    q = query(q, where('subDepartment', '==', subDepartment));
                }

                const unsubscribe = onSnapshot(q, (snapshot) => {
                    const patients = snapshot.docs.map(doc => {
                        const data = doc.data();
                        return { id: doc.id, ...data };
                    });
                    const current = patients.find(p => p.status === 'serving' && p.deskNumber === deskNumber);
                    const waiting = patients.filter(p => p.status === 'waiting');
                    setCurrentPatient(current || null);
                    setWaitingPatients(waiting);
                    setLoading(false);
                }, (err) => {
                    console.error("Error fetching queue data: ", err);
                    setError("Failed to load queue data. Please try again.");
                    setLoading(false);
                });

                // Fetch total served
                let servedQuery = query(
                    queueRef,
                    where('department', '==', department),
                    where('status', '==', 'served')
                );
                if (subDepartment && subDepartment !== 'undefined') {
                    servedQuery = query(servedQuery, where('subDepartment', '==', subDepartment));
                }
                const servedSnapshot = await getDocs(servedQuery);
                setTotalServed(servedSnapshot.size);

                return () => unsubscribe();
            } catch (err) {
                console.error("Error setting up queue listener: ", err);
                setError("Failed to set up queue listener. Please try again.");
                setLoading(false);
            }
        };

        const fetchDepartments = async () => {
            try {
                const departmentsRef = collection(db, 'departments');
                const departmentsSnapshot = await getDocs(departmentsRef);
                const departmentsData = departmentsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setDepartments(departmentsData);
            } catch (err) {
                console.error("Error fetching departments: ", err);
                setError("Failed to load departments. Please try again.");
            }
        };

        fetchQueueData();
        fetchDepartments();
    }, [department, subDepartment, deskNumber]);

    const handleNext = async () => {
        try {
            if (currentPatient) {
                await updateDoc(doc(db, 'queue', currentPatient.id), {
                    status: 'served',
                    serveEndTime: serverTimestamp()
                });
                setTotalServed(prev => prev + 1);
            }
            if (waitingPatients.length > 0) {
                const nextPatient = waitingPatients[0];
                await updateDoc(doc(db, 'queue', nextPatient.id), {
                    status: 'serving',
                    deskNumber: deskNumber,
                    serveStartTime: serverTimestamp()
                });
            }
        } catch (err) {
            console.error("Error handling next patient: ", err);
            setError("Failed to process next patient. Please try again.");
        }
    };

    const handleTransfer = () => {
        setShowTransferModal(true);
    };

    const confirmTransfer = async () => {
        try {
            if (currentPatient && selectedDepartment) {
                const updateData = {
                    department: selectedDepartment,
                    status: 'waiting',
                    deskNumber: null,
                    transferredAt: serverTimestamp()
                };
                if (selectedSubDepartment) {
                    updateData.subDepartment = selectedSubDepartment;
                }
                await updateDoc(doc(db, 'queue', currentPatient.id), updateData);
                setShowTransferModal(false);
                setSelectedDepartment('');
                setSelectedSubDepartment('');
            }
        } catch (err) {
            console.error("Error transferring patient: ", err);
            setError("Failed to transfer patient. Please try again.");
        }
    };

    const handleStart = async () => {
        try {
            if (currentPatient) {
                await updateDoc(doc(db, 'queue', currentPatient.id), {
                    serveStartTime: serverTimestamp()
                });
            }
        } catch (err) {
            console.error("Error starting service: ", err);
            setError("Failed to start service. Please try again.");
        }
    };

    const handleSkip = async () => {
        try {
            if (currentPatient) {
                await updateDoc(doc(db, 'queue', currentPatient.id), {
                    status: 'skipped',
                    deskNumber: null,
                    skippedAt: serverTimestamp()
                });
            }
        } catch (err) {
            console.error("Error skipping patient: ", err);
            setError("Failed to skip patient. Please try again.");
        }
    };

    const handleRecall = async () => {
        try {
            const recallRef = collection(db, 'queue');
            let recallQuery = query(
                recallRef,
                where('department', '==', department),
                where('status', '==', 'skipped'),
                orderBy('skippedAt', 'desc'),
                limit(1)
            );
            if (subDepartment && subDepartment !== 'undefined') {
                recallQuery = query(recallQuery, where('subDepartment', '==', subDepartment));
            }
            const recallSnapshot = await getDocs(recallQuery);
            if (!recallSnapshot.empty) {
                const recallPatient = recallSnapshot.docs[0];
                await updateDoc(doc(db, 'queue', recallPatient.id), {
                    status: 'serving',
                    deskNumber: deskNumber,
                    serveStartTime: serverTimestamp()
                });
                console.log("Patient recalled successfully");
            } else {
                console.log("No skipped patients to recall");
                setError("No skipped patients to recall.");
            }
        } catch (err) {
            console.error("Error recalling patient: ", err);
            setError("Failed to recall patient. Please try again.");
        }
    };

    const handleMoveBack = async () => {
        try {
            if (currentPatient) {
                await updateDoc(doc(db, 'queue', currentPatient.id), {
                    status: 'waiting',
                    deskNumber: null,
                    movedBackAt: serverTimestamp()
                });
            }
        } catch (err) {
            console.error("Error moving patient back: ", err);
            setError("Failed to move patient back. Please try again.");
        }
    };

    const handleShowHistory = async (patientId) => {
        try {
            const historyRef = collection(db, 'queue');
            const historyQuery = query(
                historyRef,
                where('patientId', '==', patientId),
                orderBy('timestamp', 'desc'),
                limit(10)
            );
            const historySnapshot = await getDocs(historyQuery);
            const history = historySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setPatientHistory(history);
            setShowHistoryModal(true);
        } catch (err) {
            console.error("Error fetching patient history: ", err);
            setError("Failed to fetch patient history. Please try again.");
        }
    };

    const getCurrentDate = () => {
        return new Date().toLocaleString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    };

    const calculateWaitTime = (timestamp) => {
        const now = new Date();
        const waitTime = Math.floor((now - timestamp.toDate()) / 60000); // Convert to minutes
        return waitTime;
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className="text-red-500 text-center p-4">{error}</div>;
    }

    return (
        <div className="flex flex-col h-screen bg-gray-100">
            <header className="bg-black text-white p-4 flex justify-between items-center">
                <img src={gautengLogo} alt="Gauteng Logo" className="h-12" />
                <div className="flex items-center space-x-4">
                    <button className="bg-gray-700 text-white px-4 py-2 rounded">+ Category Queues</button>
                    <span>Counter: {deskNumber}</span>
                    <span>{currentUser?.name}</span>
                    <span>{getCurrentDate()}</span>
                    <button className="bg-red-500 text-white px-4 py-2 rounded" onClick={() => navigate('/login')}>Logout</button>
                </div>
            </header>

            <main className="flex-grow flex overflow-hidden">
                <div className="w-3/4 p-8 bg-white overflow-auto">
                    <h2 className="text-3xl font-bold mb-4">Current Serving</h2>
                    {currentPatient ? (
                        <>
                            <h3 className="text-4xl font-bold mb-2">Queue Number</h3>
                            <p className="text-6xl font-bold mb-4">{currentPatient.queueNumber}</p>
                            <p className="text-xl mb-2">{department} {subDepartment && subDepartment !== 'undefined' ? `-> ${subDepartment}` : ''}</p>
                            <p className="text-2xl font-bold mb-2 cursor-pointer flex items-center" onClick={() => handleShowHistory(currentPatient.patientId)}>
                                {currentPatient.patientName}
                                <FaHistory className="ml-2" />
                            </p>
                            <p className="text-xl mb-2">{currentPatient.phoneNumber}</p>
                            <p className="text-xl mb-2">Counter: {deskNumber}</p>
                            <p className="text-xl">Issue Date: {currentPatient.timestamp.toDate().toLocaleString()}</p>
                        </>
                    ) : (
                        <p className="text-2xl">No patient currently serving</p>
                    )}
                </div>
                <div className="w-1/4 bg-gray-200 p-4 flex flex-col overflow-hidden">
                    <div className="mb-4 bg-blue-500 text-white p-2 text-center">
                        {waitingPatients.length} Patients are waiting
                    </div>
                    <div className="grid grid-cols-2 gap-2 mb-4">
                        <button onClick={handleNext} className="bg-blue-500 text-white p-2">Next</button>
                        <button onClick={handleTransfer} className="bg-blue-500 text-white p-2">Transfer</button>
                        <button onClick={handleStart} className="bg-blue-500 text-white p-2">Start</button>
                        <button onClick={handleSkip} className="bg-blue-500 text-white p-2">Skip</button>
                        <button onClick={handleRecall} className="bg-blue-500 text-white p-2">Recall</button>
                        <button onClick={handleMoveBack} className="bg-blue-500 text-white p-2">Move Back</button>
                    </div>
                    <div className="mb-4">
                        <h3 className="font-bold mb-2">Total Served Tokens</h3>
                        <p className="text-4xl font-bold">{totalServed}</p>
                    </div>
                    <div className="flex-grow overflow-auto">
                        <h3 className="font-bold mb-2">Waiting List</h3>
                        {waitingPatients.map((patient, index) => (
                            <div key={patient.id} className="bg-white p-2 mb-2 rounded flex justify-between items-center">
                                <div>
                                    <p className="font-bold cursor-pointer" onClick={() => handleShowHistory(patient.patientId)}>
                                        {patient.patientName} ({patient.queueNumber})
                                    </p>
                                    <p>{patient.phoneNumber}</p>
                                </div>
                                <div className="flex items-center">
                                    <FaClock className="text-gray-500 mr-1" />
                                    <span>{calculateWaitTime(patient.timestamp)} min</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </main>

            {showTransferModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-4 rounded">
                        <h2 className="text-xl font-bold mb-4">Transfer Patient</h2>
                        <select
                            value={selectedDepartment}
                            onChange={(e) => setSelectedDepartment(e.target.value)}
                            className="w-full mb-2 p-2 border rounded"
                        >
                            <option value="">Select Department</option>
                            {departments.map(dept => (
                                <option key={dept.id} value={dept.id}>{dept.name}</option>
                            ))}
                        </select>
                        {selectedDepartment && departments.find(d => d.id === selectedDepartment)?.hasSubDepartments && (
                            <select
                                value={selectedSubDepartment}
                                onChange={(e) => setSelectedSubDepartment(e.target.value)}
                                className="w-full mb-2 p-2 border rounded"
                            >
                                <option value="">Select Sub-Department</option>
                                {departments.find(d => d.id === selectedDepartment)?.subCategories.map(subDept => (
                                    <option key={subDept} value={subDept}>{subDept}</option>
                                ))}
                            </select>
                        )}
                        <div className="flex justify-end">
                            <button onClick={() => setShowTransferModal(false)} className="bg-gray-300 text-black px-4 py-2 rounded mr-2">Cancel</button>
                            <button onClick={confirmTransfer} className="bg-blue-500 text-white px-4 py-2 rounded">Confirm Transfer</button>
                        </div>
                    </div>
                </div>
            )}

            {showHistoryModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-4 rounded max-w-2xl w-full max-h-[80vh] overflow-auto">
                        <h2 className="text-xl font-bold mb-4">Patient Visit History</h2>
                        {patientHistory.map((visit, index) => (
                            <div key={index} className="mb-4 p-2 border rounded">
                                <p><strong>Date:</strong> {visit.timestamp.toDate().toLocaleString()}</p>
                                <p><strong>Department:</strong> {visit.department}</p>
                                <p><strong>Sub-Department:</strong> {visit.subDepartment || 'N/A'}</p>
                                <p><strong>Status:</strong> {visit.status}</p>
                            </div>
                        ))}
                        <button onClick={() => setShowHistoryModal(false)} className="bg-blue-500 text-white px-4 py-2 rounded mt-4">Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DeskServingScreen;