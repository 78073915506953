import React from 'react';

const QueueTicketDisplay = ({ ticketInfo, currentHospital }) => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')} ${currentDate.getHours() >= 12 ? 'PM' : 'AM'}`;

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
            <div className="w-full max-w-md bg-white rounded-3xl shadow-lg p-8 text-center relative">
                <div className="absolute top-0 left-0 right-0 h-16 bg-gray-200 rounded-t-3xl"></div>
                <div className="relative z-10 pt-8">
                    <h1 className="text-2xl font-semibold text-gray-700 mb-2">{currentHospital.name}</h1>
                    <h2 className="text-xl text-gray-600 mb-4">Queue No.</h2>
                    <p className="text-5xl font-bold text-gray-800 mb-4">{ticketInfo.ticketNumber}</p>
                    <p className="text-sm text-gray-500 mb-4">Arrived: {formattedDate}</p>
                    <h3 className="text-xl font-semibold text-gray-700 mb-2">{currentHospital.name}</h3>
                    <p className="text-lg text-gray-600 mb-2">{ticketInfo.department}</p>
                    <p className="text-md text-gray-500 mb-2">{ticketInfo.subCategory}</p>
                    <p className="text-lg text-gray-600 mb-4">{ticketInfo.name}</p>
                    <p className="text-sm text-gray-500 mb-6">There are 0 queuing before you</p>
                    <div className="border-t border-gray-300 pt-4">
                        <button onClick={() => window.location.reload()} className="bg-black text-white px-8 py-2 rounded-full hover:bg-gray-800 transition-colors duration-300">
                            Done
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QueueTicketDisplay;