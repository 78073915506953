import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';

const TicketDeskManagement = () => {
    const [ticketDesks, setTicketDesks] = useState([]);
    const [newDesk, setNewDesk] = useState({ name: '', department: '', subDepartment: '', deskNumber: '' });
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);

    useEffect(() => {
        fetchTicketDesks();
        fetchDepartments();
    }, []);

    const fetchTicketDesks = async () => {
        const desksCollection = collection(db, 'ticketDesks');
        const deskSnapshot = await getDocs(desksCollection);
        const deskList = deskSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setTicketDesks(deskList);
    };

    const fetchDepartments = async () => {
        const deptsCollection = collection(db, 'departments');
        const deptSnapshot = await getDocs(deptsCollection);
        const deptList = deptSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDepartments(deptList);
    };

    const addTicketDesk = async (e) => {
        e.preventDefault();
        await addDoc(collection(db, 'ticketDesks'), newDesk);
        setNewDesk({ name: '', department: '', subDepartment: '', deskNumber: '' });
        fetchTicketDesks();
    };

    const updateTicketDesk = async (id, updatedData) => {
        await updateDoc(doc(db, 'ticketDesks', id), updatedData);
        fetchTicketDesks();
    };

    const deleteTicketDesk = async (id) => {
        await deleteDoc(doc(db, 'ticketDesks', id));
        fetchTicketDesks();
    };

    const handleDepartmentChange = (e) => {
        const departmentId = e.target.value;
        setNewDesk({ ...newDesk, department: departmentId, subDepartment: '' });
        setSelectedDepartment(departments.find(dept => dept.id === departmentId) || null);
    };

    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Ticket Desk Management</h2>

            <form onSubmit={addTicketDesk} className="mb-6">
                <input
                    type="text"
                    placeholder="Desk Name"
                    value={newDesk.name}
                    onChange={(e) => setNewDesk({ ...newDesk, name: e.target.value })}
                    className="mr-2 px-3 py-2 border rounded"
                />
                <input
                    type="text"
                    placeholder="Desk Number"
                    value={newDesk.deskNumber}
                    onChange={(e) => setNewDesk({ ...newDesk, deskNumber: e.target.value })}
                    className="mr-2 px-3 py-2 border rounded"
                />
                <select
                    value={newDesk.department}
                    onChange={handleDepartmentChange}
                    className="mr-2 px-3 py-2 border rounded"
                >
                    <option value="">Select Department</option>
                    {departments.map((dept) => (
                        <option key={dept.id} value={dept.id}>{dept.name}</option>
                    ))}
                </select>
                {selectedDepartment && selectedDepartment.hasSubDepartments && selectedDepartment.subCategories && selectedDepartment.subCategories.length > 0 && (
                    <select
                        value={newDesk.subDepartment}
                        onChange={(e) => setNewDesk({ ...newDesk, subDepartment: e.target.value })}
                        className="mr-2 px-3 py-2 border rounded"
                    >
                        <option value="">Select Sub-Department</option>
                        {selectedDepartment.subCategories.map((subDept, index) => (
                            <option key={index} value={subDept}>{subDept}</option>
                        ))}
                    </select>
                )}
                <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">Add Desk</button>
            </form>
            <table className="w-full">
                <thead>
                    <tr>
                        <th className="text-left">Desk Name</th>
                        <th className="text-left">Desk Number</th>
                        <th className="text-left">Department</th>
                        <th className="text-left">Sub-Department</th>
                        <th className="text-left">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {ticketDesks.map((desk) => (
                        <tr key={desk.id}>
                            <td>{desk.name}</td>
                            <td>{desk.deskNumber}</td>
                            <td>{departments.find(d => d.id === desk.department)?.name || 'Unknown'}</td>
                            <td>{desk.subDepartment || 'N/A'}</td>
                            <td>
                                <button onClick={() => updateTicketDesk(desk.id, { name: prompt('Enter new name', desk.name) })} className="mr-2 px-3 py-1 bg-yellow-500 text-white rounded">
                                    Edit
                                </button>
                                <button onClick={() => deleteTicketDesk(desk.id)} className="px-3 py-1 bg-red-500 text-white rounded">
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TicketDeskManagement;