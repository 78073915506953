// File: src/components/DocumentationStatus.js
import React from 'react';

const DocumentationStatus = ({ onSelect }) => (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
        <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8">
            <h1 className="text-3xl font-bold text-center mb-6">Documentation Status</h1>
            <p className="text-center text-gray-600 mb-6">Are you documented or undocumented?</p>
            <div className="space-y-4">
                <button onClick={() => onSelect('Documented')} className="w-full bg-blue-500 text-white py-3 rounded-md hover:bg-blue-600 transition-colors duration-300">
                    Documented
                </button>
                <button onClick={() => onSelect('Undocumented')} className="w-full bg-green-500 text-white py-3 rounded-md hover:bg-green-600 transition-colors duration-300">
                    Undocumented
                </button>
            </div>
        </div>
    </div>
);

export default DocumentationStatus;