import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';

const AdminReports = ({ currentHospital }) => {
    const [queueData, setQueueData] = useState([]);
    const [departments, setDepartments] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (!currentHospital) return;

            // Fetch departments
            const deptsRef = collection(db, 'departments');
            const deptsSnapshot = await getDocs(deptsRef);
            const deptsData = {};
            deptsSnapshot.forEach(doc => {
                deptsData[doc.id] = doc.data().name;
            });
            setDepartments(deptsData);

            // Fetch queue data
            const queueRef = collection(db, 'queue');
            const q = query(queueRef, where('hospital', '==', currentHospital.name));
            const querySnapshot = await getDocs(q);
            const queueEntries = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                departmentName: deptsData[doc.data().department] || 'Unknown Department'
            }));
            setQueueData(queueEntries);
            setLoading(false);
        };

        fetchData();
    }, [currentHospital]);

    const getDepartmentDistribution = () => {
        const departmentCounts = {};
        queueData.forEach(entry => {
            departmentCounts[entry.departmentName] = (departmentCounts[entry.departmentName] || 0) + 1;
        });
        return Object.entries(departmentCounts).map(([name, value]) => ({ name, value }));
    };

    const getStatusDistribution = () => {
        const statuses = {};
        queueData.forEach(entry => {
            statuses[entry.status] = (statuses[entry.status] || 0) + 1;
        });
        return Object.entries(statuses).map(([name, value]) => ({ name, value }));
    };

    const getAverageWaitTimes = () => {
        const departmentWaitTimes = {};
        queueData.forEach(entry => {
            if (!departmentWaitTimes[entry.departmentName]) {
                departmentWaitTimes[entry.departmentName] = [];
            }
            departmentWaitTimes[entry.departmentName].push(entry.estimatedWaitTime);
        });

        return Object.entries(departmentWaitTimes).map(([name, times]) => ({
            name,
            averageWait: times.reduce((acc, time) => acc + time, 0) / times.length
        }));
    };

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

    if (loading) {
        return <div className="text-center mt-8">Loading reports...</div>;
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-8 text-center">Admin Reports</h1>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h2 className="text-xl font-semibold mb-4">Department Distribution</h2>
                    <PieChart width={400} height={300}>
                        <Pie
                            data={getDepartmentDistribution()}
                            cx={200}
                            cy={150}
                            labelLine={false}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                        >
                            {getDepartmentDistribution().map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </div>

                <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h2 className="text-xl font-semibold mb-4">Queue Status Distribution</h2>
                    <PieChart width={400} height={300}>
                        <Pie
                            data={getStatusDistribution()}
                            cx={200}
                            cy={150}
                            labelLine={false}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                        >
                            {getStatusDistribution().map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </div>

                <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h2 className="text-xl font-semibold mb-4">Average Wait Times by Department</h2>
                    <BarChart width={400} height={300} data={getAverageWaitTimes()}>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="averageWait" fill="#8884d8" />
                    </BarChart>
                </div>
            </div>

            <div className="mt-8 bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-xl font-semibold mb-4">Detailed Queue Information</h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full table-auto">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="px-4 py-2">Queue Number</th>
                                <th className="px-4 py-2">Patient Name</th>
                                <th className="px-4 py-2">Department</th>
                                <th className="px-4 py-2">Sub-Department</th>
                                <th className="px-4 py-2">Status</th>
                                <th className="px-4 py-2">Estimated Wait Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {queueData.map((entry) => (
                                <tr key={entry.id} className="border-b">
                                    <td className="px-4 py-2">{entry.queueNumber}</td>
                                    <td className="px-4 py-2">{entry.patientName}</td>
                                    <td className="px-4 py-2">{entry.departmentName}</td>
                                    <td className="px-4 py-2">{entry.subDepartment || 'N/A'}</td>
                                    <td className="px-4 py-2">{entry.status}</td>
                                    <td className="px-4 py-2">{entry.estimatedWaitTime} minutes</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AdminReports;