import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { db } from "./firebase";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore";
import Layout from "./components/Layout";
import RoleSelection from "./components/RoleSelection";
import DocumentationStatus from "./components/DocumentationStatus";
import IDPassportScan from "./components/IDPassportScan";
import UndocumentedForm from "./components/UndocumentedForm";
import CameraCapture from "./components/CameraCapture";
import CellNumberConfirmation from "./components/CellNumberConfirmation";
import DepartmentSelection from "./components/DepartmentSelection";
import QueueTicketDisplay from "./components/QueueTicketDisplay";
import VisitorForm from "./components/VisitorForm";
import ConfirmationScreen from "./components/ConfirmationScreen";
import Login from "./components/Login";
import AdminDashboard from "./components/admin/AdminDashboard";
import QueueWaitingScreen from "./components/QueueWaitingScreen";
import DeskServingScreen from "./components/DeskServingScreen";

const App = () => {
  const [currentHospital, setCurrentHospital] = useState(null);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    step: 1,
    userType: null,
    documentationStatus: null,
    userData: null,
    isReturning: false,
    cellNumber: "",
    department: "",
    departmentId: "",
    subCategory: "",
    ticketNumber: "",
    selfieImage: null,
    fingerprintImage: null,
    documentId: null,
  });

  useEffect(() => {
    const loadHospitalData = async () => {
      const subdomain = window.location.hostname.split(".")[0];

      const hospitalsRef = collection(db, "hospitals");
      const querySnapshot = await getDocs(hospitalsRef);

      let foundHospital = null;
      querySnapshot.forEach((doc) => {
        const hospitalData = doc.data();
        if (hospitalData.subdomain === subdomain) {
          foundHospital = { id: doc.id, ...hospitalData };
        }
      });

      if (foundHospital) {
        setCurrentHospital(foundHospital);
      } else {
        console.log("No matching hospital found");
      }
      setLoading(false);
    };

    loadHospitalData();
  }, []);

  const updateState = (newState) => {
    setState((prev) => ({ ...prev, ...newState }));
  };

  const handleRoleSelect = (role) => {
    updateState({
      userType: role,
      step: role === "Patient" ? 2 : "visitorForm",
    });
  };

  const handleDocumentationStatus = (status) => {
    updateState({
      documentationStatus: status,
      step: status === "Documented" ? 3 : 4,
    });
  };

  const handleIDPassportScan = async (scanData) => {
    try {
      const userQuery = query(
        collection(db, "patients"),
        where("documentId", "==", scanData.document_id.value)
      );
      const querySnapshot = await getDocs(userQuery);

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        const docId = querySnapshot.docs[0].id;
        updateState({
          userData,
          documentId: docId,
          isReturning: true,
          step: 6, // Go to cell number confirmation
        });
      } else {
        const newUserData = {
          documentId: scanData.document_id.value,
          name: scanData.given_names[0].value,
          surname: scanData.last_name.value,
          birthDate: scanData.birth_date.value,
          expiryDate: scanData.expire_date.value,
          createdAt: new Date(),
        };
        const docRef = await addDoc(collection(db, "patients"), newUserData);
        updateState({
          userData: { ...newUserData, id: docRef.id },
          documentId: docRef.id,
          isReturning: false,
          step: "documentedSelfie", // Go to selfie capture
        });
      }
    } catch (error) {
      console.error("Error processing ID/Passport scan:", error);
    }
  };

  const handleUndocumentedSubmit = async (formData) => {
    try {
      const userQuery = query(
        collection(db, "undocumentedPatients"),
        where("cellNumber", "==", formData.cellNumber)
      );
      const querySnapshot = await getDocs(userQuery);

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        const docId = querySnapshot.docs[0].id;
        updateState({
          userData,
          documentId: docId,
          isReturning: true,
          cellNumber: formData.cellNumber,
          step: 7,
        });
      } else {
        const newUserData = {
          ...formData,
          createdAt: new Date(),
        };
        const docRef = await addDoc(
          collection(db, "undocumentedPatients"),
          newUserData
        );
        updateState({
          userData: { ...newUserData, id: docRef.id },
          documentId: docRef.id,
          cellNumber: formData.cellNumber,
          step: "undocumentedSelfie",
        });
      }
    } catch (error) {
      console.error("Error processing undocumented patient:", error);
    }
  };

  const handleVisitorSubmit = async (formData) => {
    try {
      const visitorQuery = query(
        collection(db, "visitors"),
        where("idNumber", "==", formData.idNumber)
      );
      const querySnapshot = await getDocs(visitorQuery);

      if (!querySnapshot.empty) {
        const visitorData = querySnapshot.docs[0].data();
        const docId = querySnapshot.docs[0].id;
        updateState({
          userData: visitorData,
          documentId: docId,
          isReturning: true,
          step: "visitorSelfie",
        });
      } else {
        const newVisitorData = {
          ...formData,
          createdAt: new Date(),
        };
        const docRef = await addDoc(collection(db, "visitors"), newVisitorData);
        updateState({
          userData: { ...newVisitorData, id: docRef.id },
          documentId: docRef.id,
          step: "visitorSelfie",
        });
      }
    } catch (error) {
      console.error("Error processing visitor:", error);
    }
  };

  const handleSelfieCapture = (imageData) => {
    updateState({
      selfieImage: imageData,
      step:
        state.userType === "Patient"
          ? state.documentationStatus === "Documented"
            ? "documentedFingerprint"
            : "undocumentedFingerprint"
          : "visitorFingerprint",
    });
  };

  const handleFingerprintCapture = async (imageData) => {
    try {
      updateState({ fingerprintImage: imageData });

      if (state.documentId) {
        const collectionName =
          state.userType === "Patient"
            ? state.documentationStatus === "Documented"
              ? "patients"
              : "undocumentedPatients"
            : "visitors";

        await updateDoc(doc(db, collectionName, state.documentId), {
          selfieImage: state.selfieImage,
          fingerprintImage: imageData,
        });
      }

      updateState({ step: 6 }); // Go to cell number entry/confirmation
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  const handleCellNumberConfirmation = async (number) => {
    try {
      if (state.documentId) {
        const collectionName =
          state.documentationStatus === "Documented"
            ? "patients"
            : "undocumentedPatients";
        await updateDoc(doc(db, collectionName, state.documentId), {
          cellNumber: number,
        });
      }
      updateState({ cellNumber: number, step: 7 }); // Go to department selection
    } catch (error) {
      console.error("Error updating cell number:", error);
    }
  };

  const handleDepartmentSelect = async (dept, subCat) => {
    try {
      updateState({
        department: dept.name,
        departmentId: dept.id,
        subCategory: subCat,
      });

      if (state.userType === "Patient") {
        const prefix = "P";
        const ticketNumber = `${prefix}-${dept.name
          .substring(0, 2)
          .toUpperCase()}-${Math.floor(Math.random() * 1000)}`;
        updateState({ ticketNumber, step: 8 });

        const collectionName =
          state.documentationStatus === "Documented"
            ? "patients"
            : "undocumentedPatients";
        await updateDoc(doc(db, collectionName, state.documentId), {
          department: dept.id,
          subCategory: subCat,
          lastVisit: new Date(),
        });

        // Create a new queue entry
        const queueRef = collection(db, "queue");
        const newQueueEntry = {
          department: dept.id,
          subDepartment: subCat || null,
          hospital: currentHospital.name,
          status: "waiting",
          timestamp: serverTimestamp(),
          queueNumber: ticketNumber,
          estimatedWaitTime: 15,
          patientId: state.documentId,
          patientName: `${state.userData.name} ${state.userData.surname}`,
        };
        const queueDocRef = await addDoc(queueRef, newQueueEntry);
        console.log(
          "Queue entry created:",
          newQueueEntry,
          "with ID:",
          queueDocRef.id
        );
      } else {
        await updateDoc(doc(db, "visitors", state.documentId), {
          department: dept.id,
          subCategory: subCat,
          lastVisit: new Date(),
        });
        updateState({ step: "visitorConfirmation" });
      }
    } catch (error) {
      console.error("Error processing department selection:", error);
    }
  };

  const renderStep = () => {
    switch (state.step) {
      case 1:
        return (
          <RoleSelection
            onSelect={handleRoleSelect}
            currentHospital={currentHospital}
          />
        );
      case 2:
        return <DocumentationStatus onSelect={handleDocumentationStatus} />;
      case 3:
        return <IDPassportScan onCapture={handleIDPassportScan} />;
      case 4:
        return <UndocumentedForm onSubmit={handleUndocumentedSubmit} />;
      case 5:
        return (
          <CameraCapture
            onCapture={handleFingerprintCapture}
            title="Scan Fingerprint"
          />
        );
      case 6:
        return (
          <CellNumberConfirmation
            userType={state.userType}
            isReturning={state.isReturning}
            cellNumber={state.userData ? state.userData.cellNumber : ""}
            onSubmit={handleCellNumberConfirmation}
          />
        );
      case 7:
        return (
          <DepartmentSelection
            onSelect={handleDepartmentSelect}
            hospital={currentHospital}
          />
        );
      case 8:
        return (
          <QueueTicketDisplay
            ticketInfo={{
              ticketNumber: state.ticketNumber,
              department: state.department,
              subCategory: state.subCategory,
              name: state.userData
                ? `${state.userData.name} ${state.userData.surname}`
                : "Visitor",
            }}
            currentHospital={currentHospital}
          />
        );
      case "documentedSelfie":
      case "undocumentedSelfie":
      case "visitorSelfie":
        return (
          <CameraCapture onCapture={handleSelfieCapture} title="Take Selfie" />
        );
      case "documentedFingerprint":
      case "undocumentedFingerprint":
      case "visitorFingerprint":
        return (
          <CameraCapture
            onCapture={handleFingerprintCapture}
            title="Scan Fingerprint"
          />
        );
      case "visitorForm":
        return <VisitorForm onSubmit={handleVisitorSubmit} />;
      case "visitorConfirmation":
        return (
          <ConfirmationScreen
            department={state.department}
            subCategory={state.subCategory}
            onFinish={() => updateState({ step: 1 })}
          />
        );
      default:
        return <div>Something went wrong</div>;
    }
  };

  if (loading) {
    return (
      <Layout currentHospital={currentHospital}>
        <div>Loading...</div>
      </Layout>
    );
  }

  if (!currentHospital) {
    return (
      <Layout currentHospital={currentHospital}>
        <div>Hospital not found. Please check the URL and try again.</div>
      </Layout>
    );
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Layout currentHospital={currentHospital}>{renderStep()}</Layout>
          }
        />
        <Route
          path="/admin/*"
          element={
            <Layout currentHospital={currentHospital}>
              <AdminDashboard hospital={currentHospital} />
            </Layout>
          }
        />
        <Route
          path="/login"
          element={
            <Layout currentHospital={currentHospital}>
              <Login />
            </Layout>
          }
        />
        <Route
          path="/queue/:department/:subDepartment?"
          element={
            <Layout currentHospital={currentHospital}>
              <QueueWaitingScreen />
            </Layout>
          }
        />
        <Route
          path="/desk/:department/:subDepartment?/:deskNumber"
          element={
            <Layout currentHospital={currentHospital}>
              <DeskServingScreen hospital={currentHospital.name} />
            </Layout>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default App;
