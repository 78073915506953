import React, { useState } from "react";
import { Link } from "react-router-dom";
import DepartmentManagement from "./DepartmentManagement";
import UserManagement from "./UserManagement";
import HospitalManagement from "./HospitalManagement";
import TicketDeskManagement from "./TicketDeskManagement";
import QueueWaitingTimes from "./QueueWaitingTimes";
import VisitorReports from "./VisitorReports";
import AdminReports from "./AdminReports";

const AdminDashboard = ({ hospital }) => {
  const [activeComponent, setActiveComponent] = useState("dashboard");

  const renderComponent = () => {
    switch (activeComponent) {
      case "departments":
        return <DepartmentManagement hospital={hospital} />;
      case "users":
        return <UserManagement />;
      case "hospitals":
        return <HospitalManagement />;
      case "ticketDesks":
        return <TicketDeskManagement />;
      case "queueTimes":
        return <QueueWaitingTimes />;
      case "visitorReports":
        return <VisitorReports />;
      case "adminReports":
        return <AdminReports currentHospital={hospital} />;
      default:
        return <h2>Welcome to the Admin Dashboard for {hospital?.name}</h2>;
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <nav className="w-64 bg-white shadow-md">
        <div className="p-4">
          <h1 className="text-2xl font-bold text-gray-800">Admin Dashboard</h1>
        </div>
        <ul className="py-4">
          <li>
            <Link
              to="/admin"
              className="block px-4 py-2 text-gray-600 hover:bg-gray-200"
              onClick={() => setActiveComponent("dashboard")}
            >
              Dashboard Home
            </Link>
          </li>
          <li>
            <Link
              to="/admin/departments"
              className="block px-4 py-2 text-gray-600 hover:bg-gray-200"
              onClick={() => setActiveComponent("departments")}
            >
              Department Management
            </Link>
          </li>
          <li>
            <Link
              to="/admin/users"
              className="block px-4 py-2 text-gray-600 hover:bg-gray-200"
              onClick={() => setActiveComponent("users")}
            >
              User Management
            </Link>
          </li>
          <li>
            <Link
              to="/admin/hospitals"
              className="block px-4 py-2 text-gray-600 hover:bg-gray-200"
              onClick={() => setActiveComponent("hospitals")}
            >
              Hospital Management
            </Link>
          </li>
          <li>
            <Link
              to="/admin/ticket-desks"
              className="block px-4 py-2 text-gray-600 hover:bg-gray-200"
              onClick={() => setActiveComponent("ticketDesks")}
            >
              Ticket Desk Management
            </Link>
          </li>
          <li>
            <Link
              to="/admin/queue-times"
              className="block px-4 py-2 text-gray-600 hover:bg-gray-200"
              onClick={() => setActiveComponent("queueTimes")}
            >
              Queue Waiting Times
            </Link>
          </li>
          <li>
            <Link
              to="/admin/visitor-reports"
              className="block px-4 py-2 text-gray-600 hover:bg-gray-200"
              onClick={() => setActiveComponent("visitorReports")}
            >
              Visitor Reports
            </Link>
          </li>
          <li>
            <Link
              to="/admin/admin-reports"
              className="block px-4 py-2 text-gray-600 hover:bg-gray-200"
              onClick={() => setActiveComponent("adminReports")}
            >
              Admin Reports
            </Link>
          </li>
        </ul>
      </nav>
      <main className="flex-1 p-8 overflow-auto">{renderComponent()}</main>
    </div>
  );
};

export default AdminDashboard;
