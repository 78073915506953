import React, { useState } from 'react';

const VisitorForm = ({ onSubmit }) => {
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        idNumber: '',
        cellNumber: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
            <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8">
                <h2 className="text-2xl font-bold mb-4">Visitor Information</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="First Name"
                        className="w-full px-3 py-2 mb-3 border border-gray-300 rounded-md"
                        required
                    />
                    <input
                        type="text"
                        name="surname"
                        value={formData.surname}
                        onChange={handleChange}
                        placeholder="Surname"
                        className="w-full px-3 py-2 mb-3 border border-gray-300 rounded-md"
                        required
                    />
                    <input
                        type="text"
                        name="idNumber"
                        value={formData.idNumber}
                        onChange={handleChange}
                        placeholder="ID/Passport Number"
                        className="w-full px-3 py-2 mb-3 border border-gray-300 rounded-md"
                        required
                    />
                    <input
                        type="tel"
                        name="cellNumber"
                        value={formData.cellNumber}
                        onChange={handleChange}
                        placeholder="Cell Number"
                        className="w-full px-3 py-2 mb-3 border border-gray-300 rounded-md"
                        required
                    />
                    <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default VisitorForm;