import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';

const DepartmentManagement = ({ hospital }) => {
    const [departments, setDepartments] = useState([]);
    const [newDepartment, setNewDepartment] = useState({ name: '', hasSubDepartments: false });
    const [newSubDepartment, setNewSubDepartment] = useState({ name: '', departmentId: '' });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDepartments = async () => {
            if (!hospital || !hospital.id) {
                setError("No hospital selected. Please select a hospital first.");
                setLoading(false);
                return;
            }
            try {
                const departmentsRef = collection(db, 'departments');
                const snapshot = await getDocs(departmentsRef);
                const departmentList = snapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(dept => dept.hospitalId === hospital.id);
                setDepartments(departmentList);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching departments:", err);
                setError("Failed to load departments. Please try again later.");
                setLoading(false);
            }
        };

        fetchDepartments();
    }, [hospital]);

    const addDepartment = async (e) => {
        e.preventDefault();
        if (!hospital || !hospital.id) {
            setError("No hospital selected. Please select a hospital first.");
            return;
        }
        try {
            const docRef = await addDoc(collection(db, 'departments'), {
                ...newDepartment,
                hospitalId: hospital.id,
                subCategories: []
            });
            setDepartments([...departments, { id: docRef.id, ...newDepartment, subCategories: [] }]);
            setNewDepartment({ name: '', hasSubDepartments: false });
            setError(null);
        } catch (error) {
            console.error("Error adding department: ", error);
            setError("Failed to add department. Please try again.");
        }
    };

    const deleteDepartment = async (id) => {
        try {
            await deleteDoc(doc(db, 'departments', id));
            setDepartments(departments.filter(dept => dept.id !== id));
            setError(null);
        } catch (error) {
            console.error("Error deleting department: ", error);
            setError("Failed to delete department. Please try again.");
        }
    };

    const addSubDepartment = async (e, departmentId) => {
        e.preventDefault();
        try {
            const departmentRef = doc(db, 'departments', departmentId);
            const department = departments.find(d => d.id === departmentId);
            const updatedSubCategories = [...(department.subCategories || []), newSubDepartment.name];
            await updateDoc(departmentRef, { subCategories: updatedSubCategories });

            setDepartments(departments.map(dept =>
                dept.id === departmentId
                    ? { ...dept, subCategories: updatedSubCategories }
                    : dept
            ));
            setNewSubDepartment({ name: '', departmentId: '' });
            setError(null);
        } catch (error) {
            console.error("Error adding sub-department: ", error);
            setError("Failed to add sub-department. Please try again.");
        }
    };

    if (loading) {
        return <div>Loading departments...</div>;
    }

    if (error) {
        return <div className="text-red-500">{error}</div>;
    }

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Department Management</h2>
            <form onSubmit={addDepartment} className="mb-4">
                <input
                    type="text"
                    placeholder="Department Name"
                    value={newDepartment.name}
                    onChange={(e) => setNewDepartment({ ...newDepartment, name: e.target.value })}
                    className="mr-2 p-2 border rounded"
                />
                <label className="mr-2">
                    <input
                        type="checkbox"
                        checked={newDepartment.hasSubDepartments}
                        onChange={(e) => setNewDepartment({ ...newDepartment, hasSubDepartments: e.target.checked })}
                        className="mr-1"
                    />
                    Has Sub-Departments
                </label>
                <button type="submit" className="bg-blue-500 text-white p-2 rounded">Add Department</button>
            </form>

            {departments.map(department => (
                <div key={department.id} className="mb-4 p-4 border rounded">
                    <h3 className="text-xl font-bold">{department.name}</h3>
                    <button onClick={() => deleteDepartment(department.id)} className="bg-red-500 text-white p-1 rounded mt-2">Delete Department</button>

                    {department.hasSubDepartments && (
                        <div className="mt-2">
                            <h4 className="text-lg font-semibold">Sub-Departments</h4>
                            <ul>
                                {department.subCategories && department.subCategories.map((subDept, index) => (
                                    <li key={index}>{subDept}</li>
                                ))}
                            </ul>
                            <form onSubmit={(e) => addSubDepartment(e, department.id)} className="mt-2">
                                <input
                                    type="text"
                                    placeholder="Sub-Department Name"
                                    value={newSubDepartment.name}
                                    onChange={(e) => setNewSubDepartment({ ...newSubDepartment, name: e.target.value, departmentId: department.id })}
                                    className="mr-2 p-2 border rounded"
                                />
                                <button type="submit" className="bg-green-500 text-white p-2 rounded">Add Sub-Department</button>
                            </form>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default DepartmentManagement;